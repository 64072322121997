/* eslint-disable */
// Use type assertion for the static import
// @ts-expect-error Remote module will be available at runtime
import carescribefeManifest from "care_scribe_fe/manifest";

import type { PluginManifest } from "./pluginTypes";

const pluginMap: PluginManifest[] = [carescribefeManifest as PluginManifest];

export { pluginMap };
